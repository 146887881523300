/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { locale } from "primereact/api";
import { Calendar } from "primereact/calendar";

import { ConfirmDialog } from "primereact/confirmdialog";

import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
interface Motivo {
  id: number;
  motivo: string;
}

interface Trabalhador {
  id: number;
  trabalhador: string;
}

interface Despacho {
  id: number;
  despacho: string;
}
/* @todo Used 'as any' for types here. Will fix in next version due to onSelectionChange event type issue. */
const ChefiaHistorico = () => {
  // Aplicando a localidade globalmente
  locale(i18n.language);
  const { t } = useTranslation();
  const [falta, setFalta] = useState<Falta | null>(null); // Exemplo de inicialização com null
  const [listaFaltas, setFaltas] = useState<Falta[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    getPedidosChefiaHistorico();

    fetchUpcomingEventsData4();
    fetchUpcomingEventsData5();
    fetchUpcomingEventsData6();
    fetchUpcomingEventsData8();
    fetchUpcomingEventsData9();
  }, []);
  const getPedidosChefiaHistorico = async () => {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getPedidosChefiaHistorico",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );
        const data = await response.json();
        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data.reverse());
      }
    } catch (error) {
      console.error("Could not getPedidosChefiaHistorico", error);
    }
  };

  const [trabalhadores, setTrabalhadores] = useState<Trabalhador[]>([]);
  const [selectedTrabalhador, setSelectedTrabalhador] =
    useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData8() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setTrabalhadores(
          data.map((dado: { ID: number; nome: string }) => ({
            id: dado.ID,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [chefes, setChefes] = useState<Trabalhador[]>([]);
  const [selectedChefe, setSelectedChefe] = useState<Trabalhador | null>(null);

  async function fetchUpcomingEventsData9() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getChefes",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChefes(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [motivos, setMotivos] = useState<Motivo[]>([]);
  const [selectedMotivo, setSelectedMotivo] = useState<Motivo | null>(null);

  async function fetchUpcomingEventsData4() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getMotivos",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setMotivos(
          data.map((dado: { id: number; motivo: string }) => ({
            id: dado.id,
            motivo: dado.motivo,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despacho, setDespacho] = useState<Despacho[]>([]);
  const [selectedDespacho, setSelectedDespacho] = useState<Despacho | null>(
    null
  );

  async function fetchUpcomingEventsData5() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosSAFP",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespacho(
          data
            .slice(1) // Remove os primeiros dois itens e o último item
            .map((dado: { id: any; despacho: any }) => ({
              id: dado.id,
              despacho: dado.despacho,
            }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  const [despachoHierarquia, setDespachoHierarquia] = useState<Despacho[]>([]);
  const [selectedDespachoHierarquia, setSelectedDespachoHierarquia] =
    useState<Despacho | null>(null);

  async function fetchUpcomingEventsData6() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDespachosHierarquia ",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDespachoHierarquia(
          data.slice(1, -1).map((dado: { id: any; despacho: any }) => ({
            id: dado.id,
            despacho: dado.despacho,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  }

  type Falta = {
    id_pedido: number;
    id_motivo: number;
    motivo: string;
    estado: string;
    descricao: string;
    chefia: string;
    Inf_Desp_Hierarquia: string;
    Desp_SAFP: string;
    dataInicio: string;
    horaInicio: string;
    dataFim: string;
    horaFim: string;
    dias: string;
    dataPedido: string;
    ficheiro: number;
    id_despacho_SAFP: number;
    id_desp_Chefia: string;
    dataAutorizacaoSAFP: string;
    SAFPAutorizacao: string;
    chefeAutorizacao: string;
    dataAutorizacao: string;
    safp_observacoes: string;
    [key: string]: string | number;
  };

  const [procuraDialog, setProcuraDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [editFeriasDialog, setEditFeriasDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    document.body.classList.add("no-scroll");
    setSelectedMotivo(null);
    setSelectedChefe(null);
    setSelectedTrabalhador(null);
    setSelectedDespacho(null);
    setInfoHierarquia(undefined);
    setObservacao(undefined);
    setSelectedDespachoHierarquia(null);
    setDateI(undefined);
    setDateF(undefined);
  };

  const openProcuras = () => {
    handleVacationButtonClick();
    clean();

    setProcuraDialog(true);
  };

  const editFalta = (rowData) => {
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setObservacao("");
    setMotivo(rowData.id_motivo);
    setSelectedDespachoHierarquia(null);
    setSelectedRow(rowData.id_pedido);
    setIcon("pi-pencil");
    setErrorMessage("");

    setHeader(rowData.trabalhador);

    setEditFeriasDialog(true);
  };

  const hideProcuras = () => {
    setProcuraDialog(false);
    close();
  };

  const hideEditFerias = () => {
    setEditFeriasDialog(false);
    close();
  };

  const hideInfo = () => {
    setInfoDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null);
    setSelectedRow2(null);
  };

  const openInfo = (rowData) => {
    setFalta(rowData);
    document.body.classList.add("no-scroll");
    handleVacationButtonClick();
    setSelectedRow2(rowData.id_pedido);
    setInfoDialog(true);
  };

  const [observacao, setObservacao] = useState<string | undefined>(undefined); // Estado para armazenar a descrição
  const [infoHierarquia, setInfoHierarquia] = useState<string | undefined>(
    undefined
  ); // Estado para armazenar a descrição

  const [motivo, setMotivo] = useState<string | undefined>(undefined); // Estado para armazenar a descrição

  const [IsAuth, setIsAuth] = useState(false);

  const alterarDecisao = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsAuth) return;
    setIsAuth(true);

    const formData = new FormData();

    if (selectedRow !== null && selectedRow !== undefined) {
      formData.append("id_pedido", selectedRow);
    } else {
      setIsAuth(false);
      return null;
    }
    if (
      selectedDespachoHierarquia !== null &&
      selectedDespachoHierarquia !== undefined &&
      selectedDespachoHierarquia.id > 1
    ) {
      formData.append("justificacao", selectedDespachoHierarquia.id.toString());
    } else {
      setIsAuth(false);
      setErrorMessage(t("SELECT_DECISION"));
      return null;
    }

    if (observacao == undefined || observacao.length == 0)
      formData.append("informacao", "");
    else if (observacao.length > 0) {
      formData.append("informacao", observacao.substring(0, 100));
    } else {
      setIsAuth(false);
      setErrorMessage(t("ERR_EDIT"));
      return null;
    }

    formData.append("motivo", motivo);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/editAuthChefia",
        {
          method: "POST",
          headers: {
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        getPedidosChefiaHistorico();
        hideEditFerias();
        showNiceMessage(t("YES"));
      } else {
        setErrorMessage(t("ERR_EDIT"));
      }
    } catch (error) {
      setErrorMessage(t("ERR_EDIT"));
      console.error("An error occurred:", error);
    } finally {
      setIsAuth(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if isoString is undefined or null
    const date = new Date(dateString);

    // Get the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, "0"); // Pad single-digit days with a leading zero

    // Return formatted date
    return `${year}-${month}-${day}`;
  };

  const [IsProcura, setIsProcuras] = useState(false);

  const procura = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsProcura) return;
    setIsProcuras(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/getPedidosChefiaHistorico2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({
            motivo: selectedMotivo?.id || "",
            trabalhador: selectedTrabalhador?.id || "",
            chefe: selectedChefe?.id || "",
            descricao: observacao?.substring(0, 100) || "",
            dataInicio: dateI ? formatDate(dateI) : "", // Check if dateI is defined before calling formatDate
            dataFim: dateF ? formatDate(dateF) : "", // Check if dateF is defined before calling formatDate
            infoHierarquia: infoHierarquia || "",
            autoChefia: selectedDespachoHierarquia?.id || "",
            decisao: selectedDespacho?.id || "",
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data[0].error === "1") setFaltas([]);
        else setFaltas(data);
        setFirst(0);
        hideProcuras();
        showInfoMessage(t("SEARCH_INFO"));
      } else {
        setErrorMessage(t("SEARCH_INFO_ERR"));
      }
    } catch (error) {
      setErrorMessage(t("SEARCH_INFO_ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsProcuras(false);
    }
  };

  const reset = () => {
    setFirst(0);
    getPedidosChefiaHistorico();
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2 buttons-container2   ">
          <Button
            label={t("SEARCH")}
            icon="pi pi-search"
            className=" mr-2 procurasbutton"
            onClick={openProcuras}
            rounded
          />

          <Button
            label={t("CLEAN")}
            icon="pi  pi-refresh
            "
            className=" mr-2 procurasbutton"
            onClick={reset}
            rounded
          />
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const converterData = (dataString: string) => {
    const partes = dataString.split("/");
    // Convertendo as partes da data para números com parseInt
    const ano = parseInt(partes[2], 10);
    const mes = parseInt(partes[1], 10) - 1; // Meses começam do 0 em JavaScript
    const dia = parseInt(partes[0], 10);

    return new Date(ano, mes, dia);
  };

  const editarFalta = (rowData) => {
    const dataAtual = new Date();
    const dataAutorizacao = converterData(rowData.dataAutorizacao);
    const diferencaEmTempo = dataAtual.getTime() - dataAutorizacao.getTime();
    const diferencaEmDias = diferencaEmTempo / (1000 * 3600 * 24);

    if (rowData.Desp_SAFP == "" && diferencaEmDias <= 4) {
      if (rowData.id_motivo == 1 && rowData.id_despacho_hierarquia == 3)
        return null;
      return (
        <>
          <Button
            icon="pi pi-pencil"
            size="small"
            className="pencil"
            rounded
            style={{
              background: "none",
              color: "#323232",
              border: "1px solid rgb(231, 238, 248)",
            }}
            onClick={() => editFalta(rowData)}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const info = (rowData: Falta) => {
    return (
      <>
        <Button
          icon="pi pi-info-circle"
          size="small"
          rounded
          className="  pencil"
          style={{
            background: "none",
            color: "#323232",
            border: "1px solid rgb(231, 238, 248)",
          }}
          onClick={() => openInfo(rowData)}
        />
      </>
    );
  };

  const editFeriasFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        className="  button2"
        onClick={alterarDecisao}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const procuraDialogFooter = (
    <>
      <Button
        label={t("SEARCH")}
        icon="pi pi-search"
        text
        className="   procurasbutton"
        onClick={procura}
      />
    </>
  );

  const InfoFooter = <></>;

  const ProcurasHeader = () => (
    <div>
      <i
        className="pi pi-search"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.3rem", color: "#323232  " }}>
        {t("SEARCH")}
      </label>
    </div>
  );

  const InfoHeader = () => (
    <div>
      <i
        className="pi pi-info-circle"
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem", color: "#323232  " }}>
        {t("ADDITIONAL_INFO")}
      </label>
    </div>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [dateI, setDateI] = useState<Date | undefined>(undefined); // Inicializado como undefined
  const [dateF, setDateF] = useState<Date | undefined>(undefined); // Inicializado como undefined

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);

  const rowClassName = (rowData) => {
    if (rowData.id_pedido === selectedRow) {
      return "highlight2";
    } else if (rowData.id_pedido === selectedRow2) {
      return "highlight3";
    } else {
      return "";
    }
  };

  const [first, setFirst] = useState(0); // Estado para controlar a primeira linha visível
  const [rows, setRows] = useState(10); // Estado para controlar o número de linhas por página

  const onPageChange = (e) => {
    setFirst(e.first); // Atualiza o estado da primeira linha visível
    setRows(e.rows); // Atualiza o estado do número de linhas por página
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "error",
      icon: "pi pi-check",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const showInfoMessage = (message) => {
    toast.current?.show({
      severity: import.meta.env.VITE_API_SEVERITY,
      icon: "pi pi-search",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 1500,
    });
  };

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="mb-3 botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>

          <DataTable
            ref={dt}
            value={listaFaltas}
            dataKey="id_pedido"
            paginator
            rows={rows} // Número de linhas por página
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage={t("NO_ABSENCE")}
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
            first={first} // Atributo para controlar a primeira linha visível
            onPage={onPageChange} // Atualiza o estado quando a página muda
          >
            <Column
              body={editarFalta}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>

            <Column
              field="trabalhador"
              header={t("WORKER")}
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="motivo"
              header={t("MOTIVE")}
              style={{
                minWidth: "4rem",
                padding: "1rem",
              }}
            ></Column>

            <Column
              field="dataInicio"
              header={t("BEGIN_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaInicio"
              header={t("BEGIN_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dataFim"
              header={t("END_DATE")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="horaFim"
              header={t("END_HOUR")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="dias"
              header={t("TIME")}
              style={{ minWidth: "4rem" }}
            ></Column>
            <Column
              field="Desp_Hierarquia"
              header={t("ORDER_HIERARCHY2")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              field="Desp_SAFP"
              header={t("ORDER_SAFP2")}
              style={{ minWidth: "5rem" }}
            ></Column>
            <Column
              body={info}
              style={{
                minWidth: "2rem",
                textAlign: "center",
              }}
            ></Column>
          </DataTable>

          <Dialog
            visible={infoDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<InfoHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={InfoFooter}
            onHide={hideInfo}
          >
            <div className="field">
              <label htmlFor="descricao" style={{ fontSize: "0.9rem" }}>
                {t("DESCRIPTION")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.descricao}
              />
            </div>
            <div className="field">
              <label htmlFor="dataPedido" style={{ fontSize: "0.9rem" }}>
                {t("REQUEST_DATE")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.dataPedido}
                disabled
              />
            </div>
            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("BOSS_OBSERVATIONS")}
              </label>
              <InputText
                className="  procura-dialog"
                id="descricao"
                value={falta?.Inf_Desp_Hierarquia}
              />
            </div>
            <div className="field">
              <label htmlFor="chefe" style={{ fontSize: "0.9rem" }}>
                {t("BOSS")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.chefeAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("BOSS_INFO_DATE")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.dataAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_OBSERVATIONS")}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.safp_observacoes}
              />
            </div>

            <div className="field">
              <label style={{ fontSize: "0.9rem" }}> {t("SAFP")} </label>
              <InputText
                className="  procura-dialog"
                value={falta?.SAFPAutorizacao}
                disabled
              />
            </div>
            <div className="field">
              <label style={{ fontSize: "0.9rem" }}>
                {t("SAFP_INFO_DATE")}{" "}
              </label>
              <InputText
                className="  procura-dialog"
                value={falta?.dataAutorizacaoSAFP}
                disabled
              />
            </div>
          </Dialog>

          <Dialog
            visible={editFeriasDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog2"
            footer={editFeriasFooter}
            onHide={hideEditFerias}
          >
            <div className="field ">
              <label htmlFor="Decisao" style={{ fontSize: "0.9rem" }}>
                {t("DECISION")}
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder={t("CHANGE_DECISION")}
                className="w-full     md:w-19rem anular "
              />
            </div>

            <div className="field">
              <label htmlFor="observacoes" style={{ fontSize: "0.9rem" }}>
                {t("OBSERVAIONS")}
              </label>
              <InputText
                id="observacoes"
                placeholder={t("OPTIONAL")}
                value={observacao}
                className="anular2"
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <Dialog
            visible={procuraDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<ProcurasHeader />}
            modal
            draggable={false}
            className="p-fluid procura-dialog"
            footer={procuraDialogFooter}
            onHide={hideProcuras}
          >
            <div className="field ">
              <label
                htmlFor="Trabalhador"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("WORKER")}
              </label>
              <Dropdown
                value={selectedTrabalhador}
                onChange={(e) => setSelectedTrabalhador(e.value)}
                options={trabalhadores}
                optionLabel="trabalhador"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Motivo"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("MOTIVE")}
              </label>
              <Dropdown
                value={selectedMotivo}
                onChange={(e) => setSelectedMotivo(e.value)}
                options={motivos}
                optionLabel="motivo"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="descricao"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("DESCRIPTION")}
              </label>
              <InputText
                id="descricao"
                placeholder={t("OPTIONAL")}
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
                className=" procura-dialog w-full     md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataInicio"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("BEGIN_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateI}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setDateI(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>

            <div className="field">
              <label
                htmlFor="dataFim"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("END_DATE2")}
              </label>
              <Calendar
                id="buttondisplay"
                value={dateF}
                className=" procura-dialog w-full     md:w-19rem "
                onChange={(e) => setDateF(e.value)}
                dateFormat="dd/mm/yy"
                placeholder={t("OPTIONAL")}
              />
            </div>
            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("BOSS")}
              </label>
              <Dropdown
                value={selectedChefe}
                onChange={(e) => setSelectedChefe(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Despacho"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("ORDER_HIERARCHY3")}
              </label>
              <Dropdown
                value={selectedDespachoHierarquia}
                onChange={(e) => setSelectedDespachoHierarquia(e.value)}
                options={despachoHierarquia}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>

            <div className="field">
              <label
                htmlFor="infoHierarquia"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("INFO_DATE")}
              </label>
              <InputText
                id="infoHierarquia"
                placeholder={t("OPTIONAL")}
                className=" procura-dialog w-full     md:w-19rem "
                value={infoHierarquia}
                onChange={(e) => setInfoHierarquia(e.target.value)}
              />
            </div>

            <div className="field ">
              <label
                htmlFor="Despacho"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("ORDER_SAFP3")}
              </label>
              <Dropdown
                value={selectedDespacho}
                onChange={(e) => setSelectedDespacho(e.value)}
                options={despacho}
                optionLabel="despacho"
                placeholder={t("OPTIONAL")}
                className="w-full   motivo-dialog procura-dialog  md:w-19rem "
              />
            </div>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil"
                    label={t("ORDER_HIERARCHY3")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                      color: "#df8f34",
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // accept();
                      setSelectedRow(null); // Limpa a seleção de todas as linhas
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default ChefiaHistorico;
